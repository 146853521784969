import LogoBRST from './Logos/LogoBRST.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ lineheight: "5px" }}><b style={{ color: "09a75d"}}>Minha Roça </b> <br /> Em breve mais uma solução: </h1>
        <img src={LogoBRST} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
